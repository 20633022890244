import React, { useState, useEffect } from 'react';
import './portfolio2.css';
import LazyLoad from 'react-lazyload';
import portfolioItems from './portfolioItems'; // Correctly importing the data

const Portfolio = () => {
    const [activeItem, setActiveItem] = useState(null);
    const [hasClicked, setHasClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // Handle item click event to toggle active state
    const handleItemClick = (index) => {
        if (activeItem === portfolioItems[index]) {
            setActiveItem(null);
            setHasClicked(false);
        } else {
            setTimeout(() => {
                setActiveItem(portfolioItems[index]);
                setHasClicked(true);
            }, 300);
        }
    };

    // Preload all portfolio images when the component mounts
    useEffect(() => {
        const imagesToLoad = portfolioItems.map((item) => {
            const image = new Image();
            image.src = item.src;
            return image;
        });

        // Use Promise.all to track when all images are loaded
        Promise.all(imagesToLoad.map((image) => new Promise((resolve) => (image.onload = resolve))))
            .then(() => {
                setIsLoading(false);
            });
    }, []);

    // Render the portfolio component
    return (
        <div className="portfolio_container">
            {isLoading ? (
                <div className="loading">
                    <p>loading... :)</p>
                </div>
            ) : (
                <div className="portfolio_gallery">
                    {portfolioItems.map((item, index) => (
                        <div 
                            className={`portfolio_item ${activeItem === item ? 'active' : ''}`} 
                            key={index} 
                            onMouseEnter={() => hasClicked && setActiveItem(portfolioItems[index])} 
                            onMouseLeave={() => hasClicked && setActiveItem(null)} 
                            onClick={() => handleItemClick(index)}
                        >
                            <LazyLoad height={200} offset={100}>
                                <img 
                                    className='portfolio_item_img'
                                    src={item.src} 
                                    alt={item.title} 
                                />
                            </LazyLoad>

                            {activeItem === item && (
                                <div className="portfolio_modal active">
                                    <h2 className='albumTitle'>{item.title}</h2>
                                    <p className="albumBy">by</p>
                                    <h3 className='artistName'>{item.artist}</h3>
                                    <p className='recordLabel'>{item.label}</p>
                                    <p className='releaseDate'>released on {item.releaseDate}</p>
                                    <p className='releaseCredit'>{item.credit}</p>
                                    <a href={item.spotify} className="social-icon">
                                        <i className="fab fa-spotify"></i>
                                    </a>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Portfolio;
