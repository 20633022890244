

const portfolioItems = [
    //Gets sorted by release date, must be MM-DD-YYYY
    
    //Forward Motion Godyssey by Post Animal
    {
        src: './AlbumArt/postanimal_forwardmotiongodyssey.jpg',
        title: 'Forward Motion Godyssey',
        artist: 'Post Animal',
        label: 'Polyvinyl Record Co.',
        releaseDate: '02-14-2020',
        credit: 'Mixing / Producer / Engineer / Writer / Performer',
        spotify: 'https://open.spotify.com/album/59rHe21EvYnO5EYFBW6Epq?si=Fu7l3HnkQcyWZchxJMTMCQ',
      },
      //Love Gibberish by Post Animal
      {
        src: './AlbumArt/postanimal_lovegibberish.jpg',
        title: 'Love Gibberish',
        artist: 'Post Animal',
        label: 'Independent',
        releaseDate: '05-14-2022',
        credit: 'Mixing / Producer / Engineer / Writer / Performer',
        spotify: 'https://open.spotify.com/album/7M4Htaf5y1H7O4H49KrKq2?si=9q1RVX81RNi2n9B_LQS3Rg',
      },
      //When I Think of You in a Castle by Post Animal
      {
        src: './AlbumArt/postanimal_whenithinkofyouinacastle.jpg',
        title: 'When I Think of You in a Castle',
        artist: 'Post Animal',
        label: 'Polyvinyl Record Co.',
        releaseDate: '04-20-2018',
        credit: 'Mixing / Producer / Engineer / Writer / Performer',
        spotify: 'https://open.spotify.com/album/6mYWg8zDohA3MhzBbbHt1F?si=2IeVw7WtSiehEkreceazig',
      },
      //Worried About You by Post Animal
      {
        src: './AlbumArt/postanimal_worriedaboutyou.jpg',
        title: 'Worried About You',
        artist: 'Post Animal',
        label: 'Independent',
        releaseDate: '10-16-2020',
        credit: 'Mixing / Producer / Engineer / Writer / Performer',
        spotify: 'https://open.spotify.com/album/4WHkxpO5DlLsIDeZFljcY7?si=O2olXzJRTQOST72dC8CNGg',
      },
      //Post Animal Perform the Most Curious Water Activities by Post Animal
      {
        src: './AlbumArt/postanimal_wateractivities.jpg',
        title: 'Post Animal Perform the Most Curious Water Activities',
        artist: 'Post Animal',
        label: 'Independent',
        releaseDate: '10-27-2015',
        credit: 'Mixing / Mastering / Producer / Engineer / Writer / Performer',
        spotify: 'https://open.spotify.com/album/6Uw9scOB4zagR04P2YBQYp?si=InKvRhUlQj-Ewc5xmopNcg',
      },
      {
        src: './AlbumArt/postanimal_whenigethome.jpg',
        title: 'When I Get Home b/w Big Boy Smack',
        artist: 'Post Animal',
        label: 'Independent',
        releaseDate: '03-02-2016',
        credit: 'Mixing / Mastering / Producer / Engineer / Writer / Performer',
        spotify: 'https://open.spotify.com/album/4CQXdBOTPDyxIfQ7x99bwA?si=rBnzbjgxSh6LT2Y4zIM0RA',
      },
      {
        src: './AlbumArt/postanimal_lonelyjones.jpg',
        title: 'Lonely Jones b/w You Were Not There',
        artist: 'Post Animal',
        label: 'Independent',
        releaseDate: '05-10-2016',
        credit: 'Mixing / Mastering / Producer / Engineer / Writer / Performer',
        spotify: 'https://open.spotify.com/album/4CQXdBOTPDyxIfQ7x99bwA?si=rBnzbjgxSh6LT2Y4zIM0RA',
      },
      {
        src: './AlbumArt/postanimal_caughtinthetrap.jpg',
        title: 'Caught in the Trap b/w Violet',
        artist: 'Post Animal',
        label: 'Independent',
        releaseDate: '06-16-2016',
        credit: 'Mixing / Mastering / Producer / Engineer / Writer / Performer',
        spotify: 'https://open.spotify.com/album/4CQXdBOTPDyxIfQ7x99bwA?si=rBnzbjgxSh6LT2Y4zIM0RA',
      },
      {
        src: './AlbumArt/postanimal_levitationsessions.jpg',
        title: 'Levitation Sessions (Live)',
        artist: 'Post Animal',
        label: 'The Reverberation Appreciation Society',
        releaseDate: '09-10-2021',
        credit: 'Mixing / Writer / Performer',
        spotify: 'https://open.spotify.com/album/6ZBYioSZahmpq5we3UGsu0?si=ig0GsrcpSFGzgUUTwp_Kxw',
      },
      {
        src: './AlbumArt/postanimal_agingforest.jpg',
        title: 'Aging Forest',
        artist: 'Post Animal',
        label: 'Independent',
        releaseDate: '06-06-2023',
        credit: 'Mixing / Mastering / Producer / Engineer / Writer / Performer',
        spotify: 'https://open.spotify.com/album/1abal8HlsVP8tQGIsc9sA6?si=u7hJFmBYRea3HTOZiXi0-g',
      },
      {
        src: './AlbumArt/daallher_1167.jpg',
        title: '1167',
        artist: 'daallher',
        label: 'Independent',
        releaseDate: '08-08-2019',
        credit: 'Mixing / Mastering / Producer / Writer / Performer',
        spotify: 'https://open.spotify.com/album/0G9wmz9MXlGqVSPmn3qOmA?si=rLPIKMB7S7-bbaOi44FQCQ',
      },
      {
        src: './AlbumArt/daallher_collection.jpg',
        title: 'Collection',
        artist: 'daallher',
        label: 'Independent',
        releaseDate: '02-02-2014',
        credit: 'Mixing / Mastering / Producer / Writer / Performer',
        spotify: 'https://open.spotify.com/album/1iwTOF1ioIEqox87zAlG0l?si=6VeDrRKcTH2yvRjLUxIVdg',
      },
      {
        src: './AlbumArt/daallher_angelinacrowdedroom.jpg',
        title: 'Angel in a Crowded Room',
        artist: 'daallher',
        label: 'Independent',
        releaseDate: '02-22-2023',
        credit: 'Mixing / Mastering / Producer / Engineer / Writer / Performer',
        spotify: 'https://open.spotify.com/album/1lI3ai2PoZzMHqasVgypW1?si=rTYx-9JSTq69k84XIcviYQ',
      },
      {
        src: './AlbumArt/inthepines_sanlorenzo.jpg',
        title: 'San Lorenzo',
        artist: 'In The Pines',
        label: 'Normal Boy Records',
        releaseDate: '11-15-2018',
        credit: 'Mixing / Mastering',
        spotify: 'https://open.spotify.com/album/4r34TxFtixub8PHcSoCtAA?si=LHk2I06KS3OrHs1sNL14jw',
      },
      {
        src: './AlbumArt/inthepines_slowblink.jpg',
        title: 'Slow Blink',
        artist: 'In The Pines',
        label: 'Soul Step Records',
        releaseDate: '01-02-2021',
        credit: 'Mixing / Mastering',
        spotify: 'https://open.spotify.com/album/0Hlg4UwHKWGuX3O07RXzGZ?si=WwIsCwmUTdGQ3OhrCrOkiw',
      },
      {
        src: './AlbumArt/crawlingvines_allthisnoise.jpg',
        title: 'All This Noise',
        artist: 'Crawling Vines',
        label: 'Independent',
        releaseDate: '12-09-2022',
        credit: 'Mixing',
        spotify: 'https://open.spotify.com/album/1yUrcTp8RqsTPkswhDp8Fq?si=k8_HK-jzTKOJYftlpibRHQ',
      },
      {
        src: './AlbumArt/crawlingvines_fallingoffthemonkeybars.jpg',
        title: 'Falling off the Monkey Bars',
        artist: 'Crawling Vines',
        label: 'Independent',
        releaseDate: '11-04-2022',
        credit: 'Mixing',
        spotify: 'https://open.spotify.com/album/5UJLON5yRByj1ZWfdDgKID?si=i4uUopN-SzqTdIJhcErx-A',
      },
      {
        src: './AlbumArt/crawlingvines_nottoday.jpg',
        title: 'Not Today',
        artist: 'Crawling Vines',
        label: 'Independent',
        releaseDate: '05-27-2022',
        credit: 'Mixing',
        spotify: 'https://open.spotify.com/album/6A1shXPgr7O7Vj3prAuBxs?si=cwi5lXXpR7qRCP9tiAlF9g',
      },
      {
        src: './AlbumArt/exokays_involvement.jpg',
        title: 'Involvement',
        artist: 'Ex Okays',
        label: 'Independent',
        releaseDate: '02-01-2019',
        credit: 'Mixing',
        spotify: 'https://open.spotify.com/album/1THugPcTVx6C7Cs0MvEAMc?si=KyYoPd0YQtKtVzrlzJJpaQ',
      },
      {
        src: './AlbumArt/exokays_onlysong.jpg',
        title: 'Only Song',
        artist: 'Ex Okays',
        label: 'Independent',
        releaseDate: '12-31-2019',
        credit: 'Mixing',
        spotify: 'https://open.spotify.com/album/02nZPv899CU92FDpMqF3rz?si=B-ef6TwWQdORLVB2TMauuw',
      },
      {
        src: './AlbumArt/llollo_rabbitsmoon.jpg',
        title: 'Rabbit\'s Moon', //backslash to use apostrophe
        artist: 'Llo Llo',
        label: 'Independent',
        releaseDate: '06-25-2021',
        credit: 'Mastering',
        spotify: 'https://open.spotify.com/album/60dMcH3iwbH98Fvg1j3Q75?si=q2Hg9gJAQuS7gPkNlTKTLA',
      },
      {
        src: './AlbumArt/llollo_letmeknowpartII.jpg',
        title: 'Let Me Know (pt. II)',
        artist: 'Llo Llo',
        label: 'Independent',
        releaseDate: '08-13-2021',
        credit: 'Mixing / Mastering',
        spotify: 'https://open.spotify.com/album/4xYeRrHY4zlzvTo4bGdfox?si=Jl3nSqenS3qrBug0io802A',
      },
      {
        src: './AlbumArt/germinator_gallows.jpg',
        title: 'Gallows',
        artist: 'Germinator',
        label: 'Independent',
        releaseDate: '06-18-2021',
        credit: 'Mixing / Mastering',
        spotify: 'https://open.spotify.com/album/4f2KsJSaqZS7giE3qVlkkG?si=7-dzxSkzQb-im0RiTZWK2Q',
      },
      {
        src: './AlbumArt/germinator_solace.jpg',
        title: 'Solace',
        artist: 'Germinator',
        label: 'Independent',
        releaseDate: '06-23-2023',
        credit: 'Mixing',
        spotify: 'https://open.spotify.com/album/6B03hwNVr2Vgym4q3q0S77?si=fdfbKc5ITNSBeoZ9WN9qzQ',
      },
      {
        src: './AlbumArt/germinator_followtheunknown.jpg',
        title: 'Follow the Unknown / Glasshouse',
        artist: 'Germinator',
        label: 'Independent',
        releaseDate: '01-06-2023',
        credit: 'Mixing',
        spotify: 'https://open.spotify.com/album/3Co6sBeItYqaoPEjlqcVvG?si=oVNZcETWR1eNL-Qdt65tng',
      },
      {
        src: './AlbumArt/safekeeping_apparition.jpg',
        title: 'Apparition',
        artist: 'safekeeping',
        label: 'Independent',
        releaseDate: '11-11-2022',
        credit: 'Mixing',
        spotify: 'https://open.spotify.com/album/0XC7Mtjdk7AGzilQdbZBya?si=wT3RdGdvQtGu1BOAGRHV0A',
      },
      {
        src: './AlbumArt/safekeeping_fallingintoyou.jpg',
        title: 'Falling Into You',
        artist: 'safekeeping',
        label: 'Independent',
        releaseDate: '05-07-2022',
        credit: 'Mixing',
        spotify: 'https://open.spotify.com/album/3NnhKMgoEBtoacDYHvHnh8?si=UwciqnQ1TrqX1H52HaupVQ',
      },
      {
        src: './AlbumArt/madwoman_nowheretorun.jpg',
        title: 'Nowhere To Run',
        artist: 'MADWOMAN',
        label: 'Independent',
        releaseDate: '09-30-2022',
        credit: 'Mixing / Mastering',
        spotify: 'https://open.spotify.com/album/6V7K5K7jrjBdv5vojn1QiM?si=5sNkFP5USPiP1uQOLfA0rA',
      },
      {
        src: './AlbumArt/madwoman_insidemyhead.jpg',
        title: 'Inside My Head',
        artist: 'MADWOMAN',
        label: 'Independent',
        releaseDate: '01-27-2023',
        credit: 'Mixing / Mastering / Producer',
        spotify: 'https://open.spotify.com/album/1PlYVDLondkWaozwChFKf6?si=Y2qmijS_Rs2fp92Y5cYa5w',
      },
      {
        src: './AlbumArt/tove_prettysequence.jpg',
        title: 'Pretty Sequence',
        artist: 'TOVE',
        label: 'Independent',
        releaseDate: '01-06-2023',
        credit: 'Mixing',
        spotify: 'https://open.spotify.com/album/2hLpQmaCBNa6qtqX5dGDtG?si=7DEVUFACTX-laSgWWoWxSA',
      },
      {
        src: './AlbumArt/kidferris_faraway.jpg',
        title: 'Faraway',
        artist: 'Kid Ferris',
        label: 'Higher Frequency',
        releaseDate: '04-07-2023',
        credit: 'Mixing',
        spotify: 'https://open.spotify.com/album/3hr1VtWVfbGfrIGEHnaNpk?si=utfd1-DKQQeWbqrlz38CoA',
      },
      {
        src: './AlbumArt/citrusmaxima_sprouts.jpg',
        title: 'Sprouts',
        artist: 'Citrus Maxima',
        label: 'Independent',
        releaseDate: '04-26-2022',
        credit: 'Mixing / Mastering',
        spotify: 'https://open.spotify.com/album/2reZ5jHZXmtMT9PcQoK1eo?si=WaJJlMf0S5GZJ-Zo4T7rlw',
      },
      {
        src: './AlbumArt/smushie_doofuscasanova.jpg',
        title: 'Doofus Casanova',
        artist: 'Smushie',
        label: 'Malfroy Records',
        releaseDate: '06-10-2023',
        credit: 'Mastering',
        spotify: 'https://open.spotify.com/album/3xrAFq4KmC78nOvunJXM7Y?si=J64SChqzQIesl34uCb-_5A',
      },
      {
        src: './AlbumArt/ryangebhardt_sevenlittlenumbers.jpg',
        title: 'Seven Little Numbers',
        artist: 'Ryan Gebhardt',
        label: 'Independent',
        releaseDate: '05-26-2023',
        credit: 'Mastering',
        spotify: 'https://open.spotify.com/album/5ZEmbdBxoumJ1JwIEhbBRk?si=9d0NGhGDQEizUyIz3FIf6w',
      },
      {
        src: './AlbumArt/thehumblecheaters_holysmokes.jpg',
        title: 'Holy Smokes',
        artist: 'The Humble Cheaters',
        label: 'Independent',
        releaseDate: '06-23-2023',
        credit: 'Mastering',
        spotify: 'https://open.spotify.com/album/6R2hYp9iP7xG7rraoX7zEz?si=XlLrMuODTgSHV7wAAwKi7Q',
      },
      {
        src: './AlbumArt/themoose_renaissance.jpg',
        title: 'Renaissance',
        artist: 'The Moose',
        label: 'Desert Animal Records',
        releaseDate: '09-22-2022',
        credit: 'Mastering',
        spotify: 'https://open.spotify.com/album/5Nf5Ny7B5yPm2fWES3lIkj?si=R5TN7vGuTTKNEJwgMbnlSw',
      },
      {
        src: './AlbumArt/jcarmone_butter.jpg',
        title: 'Butter',
        artist: 'J.Carmone, C.Gilmour',
        label: 'Jay Carmour Productions',
        releaseDate: '04-20-2023',
        credit: 'Mixing / Mastering (1,2,5,6,8)',
        spotify: 'https://open.spotify.com/album/0bonZajFZ2nlGWRC2jOOoE?si=X6m-fIs2Riifi9BARKZf5Q',
      },
      {
        src: './AlbumArt/vinnycrunch_foralliknow.jpg',
        title: 'For All I Know',
        artist: 'Vinny Crunch',
        label: 'Independent',
        releaseDate: '10-07-2022',
        credit: 'Mixing',
        spotify: 'https://open.spotify.com/album/2y78TQXeh8vkTrP45p89vz?si=ke0TVMmbTv6tJX_Dbk5Kqg',
      },
      {
        src: './AlbumArt/subt_brucebanner.jpg',
        title: 'Bruce Banner',
        artist: 'Sub*T',
        label: 'Contract Kill Records',
        releaseDate: '11-19-2021',
        credit: 'Mastering',
        spotify: 'https://open.spotify.com/album/3UXW6aEM9aPy7Ce2ViHTz6?si=5a3V7lDJS3-rWV0TeafHsw',
      },
      {
        src: './AlbumArt/subt_tableforfour.jpg',
        title: 'Table for Four',
        artist: 'Sub*T',
        label: 'Contract Kill Records',
        releaseDate: '11-05-2021',
        credit: 'Mastering',
        spotify: 'https://open.spotify.com/album/1nmRSVx5LkTTn6MdzwsAdC?si=yO466n1fSqK25kmZMcedyw',
      },
      {
        src: './AlbumArt/crawlingvines_kewpid.jpg',
        title: 'Kewpid',
        artist: 'Crawling Vines',
        label: 'Independent',
        releaseDate: '09-01-2023',
        credit: 'Mastering',
        spotify: 'https://open.spotify.com/album/2jFqQ4KA3mdEl7Y9uIU7Mf?si=PouhPlGyRPCtM4wl6NW7sQ',
      },
      {
        src: './AlbumArt/inthepines_paintingbynumbers.jpg',
        title: 'Painting By Numbers',
        artist: 'In The Pines',
        label: 'Soul Step Records',
        releaseDate: '11-03-2023',
        credit: 'Mixing',
        spotify: 'https://open.spotify.com/album/664nVlbqJfqB8kISnXA6DD?si=hyzdDMNuRHaKQIDTihoGLQ',
      },
      {
        src: './AlbumArt/daallher_truewonder-turn2luv.png',
        title: 'true wonder // turn2luv',
        artist: 'daallher',
        label: 'Independent',
        releaseDate: '08-18-2023',
        credit: 'Mixing / Mastering / Producer / Engineer / Writer / Performer',
        spotify: 'https://open.spotify.com/album/08eAdxuRALuIQpTHUl0NxR?si=jVym_aPwSsOrhIRsmhEgXA',
      },
      {
        src: './AlbumArt/tove_myheedisasatellite.jpg',
        title: 'My Heed is a Satellite',
        artist: 'Tove',
        label: 'Independent',
        releaseDate: '08-18-2023',
        credit: 'Mixing 2, 3',
        spotify: 'https://open.spotify.com/album/4Ms1fPR48jvJhPmGw1Cn1D?si=87KVP19uSzSC9CqvKrqYLQ',
      },
      {
        src: './AlbumArt/longislandrailroad_longislandrailroad.jpg',
        title: 'Long Island Railroad - EP',
        artist: 'Long Island Railroad',
        label: 'Malfroy Records',
        releaseDate: '09-01-2023',
        credit: 'Mastering',
        spotify: 'https://open.spotify.com/album/4JW8pPDn7CUDHoXB5pVwGb?si=wfJ5_C6xTeKMU35mXjWLYA',
      },
      {
        src: './AlbumArt/nnamdiXpostanimal_warmviolet.jpg',
        title: 'Full Fangs',
        artist: 'Nnamdï x Post Animal',
        label: 'Independent',
        releaseDate: '11-20-2020',
        credit: 'Mixing / Producer / Engineer / Writer / Performer',
        spotify: 'https://chicagocommunityjailsupport.bandcamp.com/track/full-fangs',
      },
      {
        src: './AlbumArt/smushie_insixsketches.jpg',
        title: 'In Six Sketches',
        artist: 'Smushie',
        label: 'Malfroy Records',
        releaseDate: '11-20-2023',
        credit: 'Mastering',
        spotify: 'https://open.spotify.com/album/51y98pSsnH5MTSHGMOdfFe?si=IprW7drCRPKLYqiw40DlEg',
      },
      {
        src: './AlbumArt/meltingheads_meltingheads.jpg',
        title: 'Melting Heads',
        artist: 'Melting Heads',
        label: 'Independent',
        releaseDate: '12-01-2023',
        credit: 'Mixing / Mastering',
        spotify: 'https://open.spotify.com/album/5gYCHIR2s1kq2tB3DEXNkn?si=x7zVCrstS9yQGGoy-AOtRw',
      },
      {
        src: './AlbumArt/astrachan_littleproblem.jpg',
        title: 'Little Problem',
        artist: 'Astrachan',
        label: 'Malfroy Records',
        releaseDate: '11-02-2023',
        credit: 'Mastering',
        spotify: 'https://open.spotify.com/album/7j55WjhrT4PZaZwAJUxk7J?si=FDtyWre3TPKSCkpa8v_m1Q',
      },
      {
        src: './AlbumArt/crawlingvines_whokilledwilliamgoose.jpg',
        title: 'Who Killed William Goose?',
        artist: 'Crawling Vines',
        label: 'Independent',
        releaseDate: '01-05-2024',
        credit: 'Mixing (3) / Mastering (8)',
        spotify: 'https://open.spotify.com/album/3eL9ZRcCQbqaN13qROnKiX?si=DTuu9cwgR6SSwBvhsvjMFw',
      },
      {
        src: './AlbumArt/fuzzwahh_the4DriverbluesandI.jpg',
        title: 'The 4D River Blues and I',
        artist: 'FUZZWAHH',
        label: 'FUZZWAHH/SoSouth Music Distribution',
        releaseDate: '01-05-2024',
        credit: 'Co-Mixing / Mastering',
        spotify: 'https://open.spotify.com/album/5mQFlGYVgj4UH4M52AZ0DD?si=VyJr3lhPQ8qflTIsCDq6Aw',
      },
      {
        src: './AlbumArt/germinator_californium.jpg',
        title: 'Californium',
        artist: 'Germinator',
        label: 'Independent',
        releaseDate: '01-25-2024',
        credit: 'Mixing',
        spotify: 'https://open.spotify.com/album/1XegJ61tpimUXdMYMGE8l3?si=dKKdab_bSBmcxdDHf4ZNYw',
      },
      {
        src: './AlbumArt/ryangebhardt_softlanding.jpg',
        title: 'If All My Dreams Survive',
        artist: 'Ryan Gebhardt',
        label: 'Independent',
        releaseDate: '02-08-2024',
        credit: 'Mastering',
        spotify: 'https://open.spotify.com/album/3HaKgjWTctX0xUuLYPBr2F?si=ZPexQoC5TJGmGnISQygRtg',
      },
      {
        src: './AlbumArt/nolonesome_coolwaters.jpg',
        title: 'Cool Waters',
        artist: 'No Lonesome',
        label: 'Independent',
        releaseDate: '02-06-2024',
        credit: 'Mastering',
        spotify: 'https://open.spotify.com/album/1luctw2Blx0whZlYXYKcv0?si=7DYjKsvTRbS8nXHJXUCELA',
      },
      {
        src: './AlbumArt/germinator_thumbonthescale.jpg',
        title: 'Thumb on the Scale',
        artist: 'Germinator',
        label: 'Independent',
        releaseDate: '05-16-2024',
        credit: 'Mixing',
        spotify: 'https://open.spotify.com/album/60V8g8qWrMY43J4MNTZXBh?si=jFwWeRwvRymQML91IX6MIw',
      },
      {
        src: './AlbumArt/bertabigtoe_imaginationloveandcourage.jpg',
        title: 'Imagination, Love and Courage',
        artist: 'Berta Bigtoe',
        label: 'Shmuck Records',
        releaseDate: '07-30-2024',
        credit: 'Mastering',
        spotify: 'https://open.spotify.com/album/4dKmfrojpNspDz5S8CblTq?si=TvxVgP7aTbG7oCC3VuoHig',
      },
      {
        src: './AlbumArt/wyandotte_pinkmountain.jpg',
        title: 'Pink Mountain',
        artist: 'Wyandotte',
        label: 'Independent',
        releaseDate: '05-24-2024',
        credit: 'Mastering',
        spotify: 'https://open.spotify.com/album/6MJRJwYE0coT6tBWenW7KN?si=6-TK65EJT4-cLsIPbeSlKQ',
      },
      {
        src: './AlbumArt/astrachan_badman.jpg',
        title: 'Bad Man',
        artist: 'Astrachan',
        label: 'Happen Twice',
        releaseDate: '08-05-2024',
        credit: 'Mastering',
        spotify: 'https://open.spotify.com/album/2eSswYZ3ObsQb1sJayRgd9?si=9cILFzGrSISQbCfj3IA3Sw',
      },
      {
        src: './AlbumArt/oldpup_spidertowns.jpg',
        title: 'Spider Towns',
        artist: 'Old Pup',
        label: 'Independent',
        releaseDate: '06-12-2024',
        credit: 'Mastering',
        spotify: 'https://open.spotify.com/album/2azUchEgFfI1EHz0JtDnEK?si=S_XaL-_dToqrtfnSaQTm_w',
      },
      {
        src: './AlbumArt/tunnel_stuck.jpg',
        title: 'Stuck (Inside a Tunnel)',
        artist: 'Tunnel',
        label: 'Independent',
        releaseDate: '07-03-2024',
        credit: 'Mastering',
        spotify: 'https://open.spotify.com/album/1yOY80nJmwrucaG6GTJAAU?si=Fe7sFQpPQSiJRojwZJi6SA',
      },
      {
        src: './AlbumArt/floralcouches_xtramild.jpg',
        title: 'Xtra Mild',
        artist: 'Floral Couches',
        label: 'Floral Records',
        releaseDate: '04-19-2024',
        credit: 'Mastering',
        spotify: 'https://open.spotify.com/album/1pGBPyS5F7llaNvtoBfoyh?si=ZyvGUWIjT3aHD4gX-joliQ',
      },
      {
        src: './AlbumArt/germinator_germinator.jpg',
        title: 'Germinator',
        artist: 'Germinator',
        label: 'Independent',
        releaseDate: '08-30-2024',
        credit: 'Mixing',
        spotify: 'https://open.spotify.com/album/4MyhYBOBmWB8Q10nB134un?si=1oxbYGm-RAS3UXr3PwUAcA',
      },
      {
        src: './AlbumArt/fuzzwahh_germ.jpg',
        title: 'Germ',
        artist: 'FUZZWAHH',
        label: 'SoSouth Music Distribution',
        releaseDate: '08-09-2024',
        credit: 'Mixing / Mastering (1-7, 9)',
        spotify: 'https://open.spotify.com/album/6OziwxU5HtPlmxcpeq1pos?si=PTxKSRpsQqGfRYIlLX58oQ',
      },

      /*
      {
        src: './AlbumArt/',
        title: '',
        artist: '',
        label: '',
        releaseDate: 'MM-DD-YYYY',
        credit: ' ',
        spotify: '',
      },
      */

      
]

//Sort in ascending order
portfolioItems.sort((a, b) => {
    const datePartsA = a.releaseDate.split('-');
    const datePartsB = b.releaseDate.split('-');
    const dateA = new Date(`${datePartsA[2]}-${datePartsA[0]}-${datePartsA[1]}`);
    const dateB = new Date(`${datePartsB[2]}-${datePartsB[0]}-${datePartsB[1]}`);

    return dateB.getTime() - dateA.getTime(); // Descending order (newest first)
});

export default portfolioItems;